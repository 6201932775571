import { createApp } from 'vue'
import { createRouter,createWebHistory } from 'vue-router'
// import  VMdEditor  from '@kangc/v-md-editor'
// import '@kangc/v-md-editor/lib/style/base-editor.css'

import VMdPreview from '@kangc/v-md-editor/lib/preview'
import '@kangc/v-md-editor/lib/style/preview.css'
import githubTheme from "@kangc/v-md-editor/lib/theme/github"
import "@kangc/v-md-editor/lib/theme/style/github.css"


import routes from './routes/routes'
import App from './App.vue'

// const router = new VueRouter({
//     mode:"history",
//     routes,
// })
const router = createRouter({
    history:createWebHistory(),
    routes,
})
const app = createApp(App);
app.use(router)
// VMdEditor.use(githubTheme)
VMdPreview.use(githubTheme)

// app.use(VMdEditor)
app.use(VMdPreview)
app.mount('#app')
