<template>
    <div>
        <header-com></header-com>
        <footer-com></footer-com>
    </div>
</template>

<script>
   import HeaderCom from "@/components/header"
import FooterCom from "@/components/footer"
    export default {
        name:"CategoryView",
        props:["category"],
        components:{
        HeaderCom,
        FooterCom
        },
        setup(props) {
          console.log("set up " +props.category)  
        },
        created() {
            
            this.$watch(
                ()=>this.$route.params,(toroute,preroute)=>{
                    console.log(toroute,preroute)
                }
            )
        },
    }
</script>

<style lang="css" scoped>

</style>