<template>
    <div>
        <v-md-preview :text="article"></v-md-preview>
    </div>
</template>

<script>

import axios from "axios"
    export default {
        name:"ArchiveView",
        props:["archiveid"],
        
        data(){
            return {
                article:"# title"
            };
        },
        setup(props) {
            console.log(props)
        },
        created() {
            this.$watch(
                ()=>this.$route.params,(toroute,preroute)=>{
                    console.log(toroute,preroute)
                }
            )
        },
        mounted() {
            let _this = this;
            
            axios.get('/api/archive/'+this.archiveid)
                .then(function (response) {
                    if(response.status == 200) {
                        let data = response.data;
                        _this.article = data['archive']['text']
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    }

</script>

<style lang="css" scoped>

</style>