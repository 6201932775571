import CategoryView from "../views/category"
import ArchiveView from "../views/archives"

const routes = [
    
    {
        path:"/category/:category",
        component:CategoryView,
        props:true,
    },
    {
        path:"/archives/:archiveid",
        component:ArchiveView,
        props:true,
    },
    {
        path:"/",
        component:CategoryView
    },
]

export default routes;