<template>
    <div class="header">
        <div class="content">
            
        </div>
    </div>
</template>

<script>
    export default {
        name:"HeaerCom",
        setup() {
            
        }
    }
</script>

<style lang="css" scoped>
    .header{
        width: 100%;
        height: 60px;
        background-color: white;
    }
    .content{
        width: 1140px;
        margin:0 auto;
    }
</style>