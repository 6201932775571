<template>
  <div id="app">
    <header-com></header-com>
    <router-view/>
    <footer-com></footer-com>
  </div>
</template>

<script>
 import HeaderCom from "@/components/header"
import FooterCom from "@/components/footer"
export default {
  name: 'App',
  components:{
        HeaderCom,
        FooterCom
        },
 
}
</script>

<style>
*{margin:0;padding:0;}
#app,body{
  background-color: #ebebeb;
}
</style>
